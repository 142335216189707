import React, { useState, useEffect } from 'react';
import {
  Button,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  MenuSplitGroup,
  makeStyles,
  MenuItemLink,
  MenuDivider,
  MenuGroupHeader
} from "@fluentui/react-components";
import { AlignLeft32Regular } from "@fluentui/react-icons";
import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/footer.component';
import MainButtonMenu from '../../components/main-buttons-menu/mainButtonMenu.component';


const useStyles = makeStyles({
  root: { color: 'black' },
});

const Navigation = ({ theme }) => {
  const [crossAxis] = React.useState(30);
  const [mainAxis] = React.useState(10);

  const classes = useStyles();

  return (
    <div className={`App ${theme}`}>
      <div className='mainButtonForSector'>
        <Menu positioning={{ position: "below-start", offset: { crossAxis, mainAxis } }}>
          <MenuTrigger disableButtonEnhancement>
            <Button className={classes.root} appearance="transparent" size='large' icon={<AlignLeft32Regular />} shape='circular'>Explore</Button>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              <MenuItemLink href="/">Home</MenuItemLink>
              {/* <MenuItemLink href="/fall-winter">Fall-Winter</MenuItemLink> */}
              <MenuDivider />
              <Menu>
                <MenuSplitGroup>
                  <MenuItemLink href="/">Home appliances</MenuItemLink>
                  <MenuTrigger disableButtonEnhancement>
                    <MenuItem aria-label="Open on platform" />
                  </MenuTrigger>
                </MenuSplitGroup>
                <MenuPopover>
                  <MenuList>
                    <MenuItem>Refrigerators</MenuItem>
                    <MenuItem>Freezers</MenuItem>
                    <MenuItem>Washing Machines</MenuItem>
                    <MenuItem>Dishwashers</MenuItem>
                    <MenuItem>Air Conditioners</MenuItem>
                    <MenuItem>Microwaves</MenuItem>
                    <MenuItem>Water Heaters</MenuItem>
                    <MenuItem>Cooling and Wine Cabinets</MenuItem>
                    <MenuItem>vacuum cleaner</MenuItem>
                    <MenuItem>clothes iron</MenuItem>
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <MenuItem>Small Kitchen Appliances</MenuItem>
                      </MenuTrigger>
                      <MenuPopover>
                        <MenuList>
                          <MenuItem>Blenders</MenuItem>
                          <MenuItem>Juicers</MenuItem>
                          <MenuItem>Coffee Machines</MenuItem>
                          <MenuItem>Mixers</MenuItem>
                          <MenuItem>Food Processors</MenuItem>
                          <MenuItem>Kettles</MenuItem>
                          <MenuItem>Bread Makers</MenuItem>
                          <MenuItem>Toasters</MenuItem>
                          <MenuItem>fryer</MenuItem>
                          <MenuItem>Rice Cooker</MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </MenuList>
                </MenuPopover>
              </Menu>
              <Menu>
                <MenuSplitGroup>
                  <MenuItemLink href="/">Electronics</MenuItemLink>
                  <MenuTrigger disableButtonEnhancement>
                    <MenuItem aria-label="Open on platform" />
                  </MenuTrigger>
                </MenuSplitGroup>
                <MenuPopover>
                  <MenuList>
                    <MenuItem>TV</MenuItem>
                    <MenuItem>Laptops</MenuItem>
                    <MenuItem>Desktops</MenuItem>
                    <MenuItem>Monitors</MenuItem>
                    <MenuItem>Tablets</MenuItem>
                    <MenuItem>Gaming</MenuItem>
                    <MenuItem>Audio</MenuItem>
                    <MenuItem>Sell Phones</MenuItem>
                    <MenuItem>Cameras</MenuItem>
                    <MenuItem>Office Equipments</MenuItem>
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <MenuItem>Computers Accessories</MenuItem>
                      </MenuTrigger>
                      <MenuPopover>
                        <MenuList>
                          <MenuItem>Mouse</MenuItem>
                          <MenuItem>Keyboard</MenuItem>
                          <MenuItem>Mouse Pad</MenuItem>
                          <MenuItem>Chargers</MenuItem>
                          <MenuItem>Headphone Stands</MenuItem>
                          <MenuItem>Webcams</MenuItem>
                          <MenuItem>Microphones</MenuItem>
                          <MenuItem>Ergonomic Chairs</MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </MenuList>
                </MenuPopover>
              </Menu>
              <Menu>
                <MenuSplitGroup>
                  <MenuItem>Household Essenitals</MenuItem>
                  <MenuTrigger disableButtonEnhancement>
                    <MenuItem aria-label="Open on platform" />
                  </MenuTrigger>
                </MenuSplitGroup>
                <MenuPopover>
                  <MenuList>
                    <MenuItem>Esential</MenuItem>
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <MenuItem>Shoes</MenuItem>
                      </MenuTrigger>
                      <MenuPopover>
                        <MenuList>
                          <MenuItem>Sneakers</MenuItem>
                          <MenuItem>Boots and Anle</MenuItem>
                          <MenuItem>Stoles and foulards</MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </MenuList>
                </MenuPopover>
              </Menu>
              <MenuItemLink href="/apps">Kids</MenuItemLink>
              <MenuItemLink href="/apps">At Home</MenuItemLink>
              <MenuGroupHeader>Section header</MenuGroupHeader>
              <MenuItem>Preferences</MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
      <div className='row sdsdweew'>
        <div className='naslovnicaLogo'>
          <Link to="/">
            <img className='naslovnicaLogo33' src='logo_transparent.png' />
          </Link>
        </div>
      </div>
      <div className='col-12'>
        <MainButtonMenu />
      </div>
      <div className='mt-4'>
        <Outlet />
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Navigation;
