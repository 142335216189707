import React from "react";
import './newArrivals.styles.css'

const NewArrivals = ({ theme }) => {
  return (
    <div>
      <div className="asdawqwew123232">
        <h2 className="mostPopularNaslov">Embrace the Chill: Top Winter Picks Just for You</h2>
      </div>
      <div className='mainDivProductfornewArrival'>
        <div className='asdcscsd22323ssd33'>
          <a href='#'>
            <img className='image323sade asdaqweq2' src='https://img.freepik.com/free-photo/full-length-portrait-three-women-dresses-celebrating-new-year_197531-13054.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais' />
            <div className='centered2'>
              <h2 className="asdasd22">
                NEW YEAR'S EVE OUTFIT
              </h2>
            </div>
          </a>
        </div>
        <div className='asdcscsd22323ssd33'>
          <a href='#'>
            <img className='image323sade asdaqweq2' src='https://img.freepik.com/premium-photo/autumn-concept-with-sweater-coffee-boots_185193-18889.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais' />
            <div className='centered2'>
              <h2 className="asdasd22">
                WINTER ESSENTIALS
              </h2>
            </div>
          </a>
        </div>
        <div className='asdcscsd22323ssd33'>
          <a href='#'>
            <img className='image323sade asdaqweq2' src='https://img.freepik.com/premium-photo/gift-boxes-gray-shiny-christmas-balls-black-table_262795-265.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais' />
            <div className='centered2'>
              <h2 className="asdasd22">
                GIFTS IDEAS
              </h2>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default NewArrivals;