import './mostPopular.css';
import React from "react";

const MostPopular = ({ theme }) => {
  return (
    <div className='mostPopularPocetak'>
      <div className='asdcscsd223'>
        <a href='#'>
          <img className='image323sade' src='https://img.freepik.com/premium-photo/black-room-with-black-chair-lamp_616652-90.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais' />
          <div className='centered'>
            <h2>
              FURNITURE & DECOR
            </h2>
          </div>
        </a>
      </div>
      <div className='asdcscsd223'>
        <a href='#'>
          <img className='image323sade' src='https://img.freepik.com/free-photo/set-various-digital-devices_23-2147864697.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=sph' />
          <div className='centered'>
            <h2>
              ELECTRONICS & GADGETS
            </h2>
          </div>
        </a>
      </div>
      <div className='asdcscsd223'>
        <a href='#'>
          <img className='image323sade' src='https://img.freepik.com/free-photo/electric-blender-mixer-juicer-set_140725-7263.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais' />
          <div className='centered'>
            <h2>
              HOME APPLIANCES
            </h2>
          </div>
        </a>
      </div>
      <div className='asdcscsd223'>
        <a href='#'>
          <img className='image323sade' src='https://img.freepik.com/free-photo/towel-near-sports-supplies_23-2147750791.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais' />
          <div className='centered'>
            <h2>
              HEALTH & WELLNESS
            </h2>
          </div>
        </a>
      </div>
      <div className='asdcscsd223'>
        <a href='#'>
          <img className='image323sade' src='https://img.freepik.com/premium-photo/dog-cat-portrait-black-white-stylish-photo-friendship-cat-dog-pug-thai-cat_182816-886.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais' />
          <div className='centered'>
            <h2>
              PET
            </h2>
          </div>
        </a>
      </div>
      <div className='asdcscsd223'>
        <a href='#'>
          <img className='image323sade' src='https://img.freepik.com/free-photo/top-view-tools-gardening_23-2148672677.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais' />
          <div className='centered'>
            <h2>
              OUTDOOR & GARDEN
            </h2>
          </div>
        </a>
      </div>
      <div className='asdcscsd223'>
        <a href='#'>
          <img className='image323sade' src='https://img.freepik.com/free-photo/kid-playing-with-toy-dinosaurs_23-2148131087.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais' />
          <div className='centered'>
            <h2>
              TOYS,KIDS & BABY
            </h2>
          </div>
        </a>
      </div>
      <div className='asdcscsd223'>
        <a href='#'>
          <img className='image323sade' src='https://img.freepik.com/free-photo/composition-different-traveling-elements_23-2148884937.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais' />
          <div className='centered'>
            <h2>
              ACCESSORIES
            </h2>
          </div>
        </a>
      </div>
    </div>
  )
}

export default MostPopular;