import React from "react";
import './footer.styles.css'

const Footer = () => {
  return (
    <div className="footerMainDiv">
      <a href="mailto:account@noirblancselect.com">Collaboration</a>
    </div>
  )
}

export default Footer;