import React from "react";
import './mainButtonMenu.styles.css';

import {
  makeStyles,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  shorthands
} from "@fluentui/react-components";

const useStyles = makeStyles({
  wrapper: {
    columnGap: "20px",
    display: "flex",
    ...shorthands.margin("15px", '0px', "0px", "0px"),
    zIndex: "100",
  }
});


const MainButtonMenu = () => {
  const styles = useStyles();

  return (
    <div className="d-flex justify-content-center align-items-end">
      <div className={styles.wrapper}>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <MenuButton className="asd234s" menuIcon={null} shape="circular">NEW ARRIVALS</MenuButton>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem>Latest fashion trends and new clothing arrivals</MenuItem>
              <MenuItem>New styles and arrivals in shoes and bags</MenuItem>
              <MenuItem>Newest arrivals in children's clothing and toys</MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>

        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <MenuButton className="asd234s" menuIcon={null} shape="circular">SALE</MenuButton>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem>Discover great deals on the latest technology gadgets</MenuItem>
              <MenuItem>Affordable essentials and adorable outfits for your little ones</MenuItem>
              <MenuItem>Spoil your pets with discounted accessories and toys</MenuItem>
              <MenuItem>Explore great deals on electronic devices and accessories</MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>

        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <MenuButton className="asd234s" menuIcon={null} shape="circular">MOST POPULAR</MenuButton>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem>Find the accessories that everyone is raving about</MenuItem>
              <MenuItem>Treat yourself with the most popular beauty products</MenuItem>
              <MenuItem>Gear up with the most popular sports and outdoor equipment</MenuItem>
              <MenuItem>Explore the most popular electronic devices and accessories</MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </div>
  )
}

export default MainButtonMenu;