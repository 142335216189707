import React from "react";
import './main-images-menu.styles.css'
const MainImagesMenu = () => {
  return (
    <div className="rowxssxd">
      <div className="column23232">
        <img className="image1 image11" src="https://img.freepik.com/premium-photo/creative-photo-european-man-black-suit-young-african-american-woman-with-curly-hair-bla_661495-6020.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=sph" />
      </div>
      <div className="column23232 image22">
        <img className="image2" src="https://img.freepik.com/free-photo/travel-still-life-pack-flat-lay_23-2148837352.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais" />
      </div>
      <div className="column23232 image33">
        <img className="image3" src="https://img.freepik.com/free-photo/modern-man-tech-collection-wooden-desk-headphones-sunglasses-smartphone-generated-by-ai_24640-92923.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais" />
      </div>
      <div className="column23232 image44">
        <img className="image4" src="https://img.freepik.com/premium-photo/black-dress-hanger-with-stylish-accessories-nearby-created-with-generative-ai_419341-53187.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais" />
      </div>
      <div className="column23232 image55">
        <img className="image5" src="https://img.freepik.com/premium-photo/black-women-s-shoes-scarf-bench-wooden-background_906149-10295.jpg?size=626&ext=jpg&ga=GA1.1.742661079.1698702032&semt=ais" />
      </div>
    </div>
  )
}

export default MainImagesMenu