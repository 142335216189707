import './chose-color.styles.css';

const ChoseColor = ({ setTheme }) => {
  return (
    <div>
      <div className='naslovnica'>
        <h1 onClick={() => setTheme('light')} className='naslovnicaFont whiteMainStyle'>WHITE</h1>
        <h1 onClick={() => setTheme('dark')} className='naslovnicaFont blackMainStyle'>BLACK</h1>
      </div>
      <div className='row col-12 g-0 text-center'>
        <h6 className='choseClassStyle'>chose color</h6>
      </div>
    </div>
  )
}

export default ChoseColor;