import MostPopular from "../../components/most-popular/mostPopular";
import NewArrivals from "../../components/newArrivals/newArrivals.component";
import MainImagesMenu from '../../components/main-images-menu/main-images-menu.component';

const Home = ({ theme }) => {
  return (
    <div>
      <div className='col-12 g-0'>
        <MainImagesMenu />
      </div>
      <MostPopular theme={theme} />
      <div className='NewArrivalsMain'>
        <NewArrivals theme={theme} />
      </div>
    </div>
  )
}

export default Home;