import React from "react";
import ChoseColor from "../../components/chose-color/chose-color.component";

const FallWinterSection = ({ setTheme }) => {
  return (
    <div>
      <ChoseColor setTheme={setTheme} />
    </div>
  )
}

export default FallWinterSection;