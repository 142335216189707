import { Container } from 'react-bootstrap';
import './App.css';
import React, { useState, useEffect } from 'react';
import Navigation from './routes/navigation/navigation.component';
import { Route, Routes } from 'react-router-dom';
import Home from './routes/home/home.component';
import FallWinterSection from './routes/fall-winter-section/fall-winter-section.component';

function App() {
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || 'light'
  );

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
  }, [theme]);

  return (
    <Container fluid className='g-0'>
      <Routes>
        <Route path='/' element={<Navigation theme={theme} setTheme={setTheme} />}>
          <Route index element={<Home theme={theme} />} />
          <Route path='/fall-winter' element={<FallWinterSection setTheme={setTheme} />} />
        </Route>
      </Routes>
    </Container>
  );
}

export default App;
